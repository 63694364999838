import { graphql } from 'gatsby'
import * as React from 'react'
import Helmet from 'react-helmet'

import PostContent from '../components/Blog/PostContent'
import PostHeader from '../components/Blog/PostHeader'
import LatestPosts from '../components/LatestPosts'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'
import { Divider } from '../components/shared/Divider'
import { Container } from '../components/shared/Grid'
import { IPost } from '../interfaces/blog'
import Layout from './'

const RenderBlogPost: React.FC<IPost> = ({ data, pageContext }) => {
  const blogMessage = data.blogYaml.blog.blogMessage
  const post = data.markdownRemark
  const pageDescription = post.excerpt
  const image =
    post && post.frontmatter.hero && post.frontmatter.hero.childImageSharp
  const postSlug = pageContext.slug

  return (
    <Layout>
      <Helmet>
        <script async defer src="https://platform.twitter.com/widgets.js" />
      </Helmet>
      <SEO
        custom={{
          description: post && pageDescription,
          image: 'resize' in image ? image.resize.src : '',
          title: post && post.frontmatter.title,
          url: post.fields.slug,
        }}
      />
      <Container>
        <PostHeader linkMessage={blogMessage.link} data={post.frontmatter} />
        <PostContent html={post.html} categories={post.frontmatter.category} />
        <Divider />
      </Container>
      <LatestPosts message="innerBlog" excludesSlug={[postSlug]} />
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    blogYaml {
      blog {
        blogMessage {
          link
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 100)
      id
      html: htmlAst
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        category
        author
        appLink
        hero {
          childImageSharp {
            resize(width: 1200, height: 600) {
              src
            }
          }
        }
      }
    }
  }
`

export default RenderBlogPost
