import { Link as GatsbyLink } from 'gatsby'
import * as React from 'react'

import ConcatWords from '../../../utils/ConcatWords'
import {
  generateAuthorUrl,
  generateCategoryUrl,
} from '../../../utils/generateUrl'
import { Box, Flex } from '../../shared/Helpers'
import Link from '../../shared/Link'
import { FootNote, SubTitle } from '../../shared/Typography'
import { CategoryTag, Image, Wrapper } from './parts'
import { IProp } from './types'

export default function PostHeader({ linkMessage, data }: IProp) {
  const authorCount = data.author.length
  const image = data.hero.childImageSharp

  return (
    <Wrapper>
      {'resize' in image && (
        <Image>
          <span>
            <img {...image.resize} alt={data.title} />
          </span>
        </Image>
      )}
      <Box mt={['2.5em']} mb={['1em']}>
        {data.category.map(cat => (
          <CategoryTag key={cat}>
            <GatsbyLink to={generateCategoryUrl(cat)}>{cat}</GatsbyLink>
          </CategoryTag>
        ))}
      </Box>
      <Flex width="100%" justifyContent="center">
        <Flex maxWidth="747px" width="100%" justifyContent="center">
          <SubTitle my=".5em">{data.title}</SubTitle>
        </Flex>
      </Flex>

      <Flex justifyContent="center" mb={['2.5em']}>
        <FootNote color="greyMedium">
          By{' '}
          {data.author.map((aut, index) => (
            <ConcatWords arrCount={authorCount} index={index} key={aut}>
              <GatsbyLink to={generateAuthorUrl(aut)}>{aut}</GatsbyLink>
            </ConcatWords>
          ))}
        </FootNote>
        <FootNote mx=".5em">·</FootNote>
        <FootNote style={{ whiteSpace: 'nowrap' }} color="greyMedium">
          {data.date}
        </FootNote>
      </Flex>
      {data.appLink && (
        <Flex mt="-2em" mb="2.5em" width="100%" justifyContent="center">
          <Link style={{ fontSize: '0.8em' }} color="blue" to={data.appLink}>
            {linkMessage}
          </Link>
        </Flex>
      )}
    </Wrapper>
  )
}
